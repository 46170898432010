import DashboardLayout from "@/pages/Dashboard/Layout/DashboardLayout.vue";
import AuthLayout from "@/pages/Dashboard/Pages/AuthLayout.vue";

// Dashboard pages

// Pages
import Login from "@/pages/Dashboard/Pages/Login.vue";

// Components pages
import Buttons from "@/pages/Dashboard/Components/Buttons.vue";
import GridSystem from "@/pages/Dashboard/Components/GridSystem.vue";
import Icons from "@/pages/Dashboard/Components/Icons.vue";
import Notifications from "@/pages/Dashboard/Components/Notifications.vue";
import Panels from "@/pages/Dashboard/Components/Panels.vue";
import SweetAlert from "@/pages/Dashboard/Components/SweetAlert.vue";
import Typography from "@/pages/Dashboard/Components/Typography.vue";

// Users Route Routes
import Users from "@/pages/Users/UserList.vue";

import auth from '../middleware/auth';
import beforeAuth from '../middleware/beforeAuth';


let componentsMenu = {
  path: "/components",
  component: DashboardLayout,
  redirect: "/components/buttons",
  name: "Components",
  children: [
    {
      path: "buttons",
      name: "Buttons",
      components: { default: Buttons },
    },
    {
      path: "grid-system",
      name: "Grid System",
      components: { default: GridSystem },
    },
    {
      path: "panels",
      name: "Panels",
      components: { default: Panels },
    },
    {
      path: "sweet-alert",
      name: "Sweet Alert",
      components: { default: SweetAlert },
    },
    {
      path: "notifications",
      name: "Notifications",
      components: { default: Notifications },
    },
    {
      path: "icons",
      name: "Icons",
      components: { default: Icons },
    },
    {
      path: "typography",
      name: "Typography",
      components: { default: Typography },
    },
  ],
};

let authPages = {
  path: "/",
  component: AuthLayout,
  name: "Authentication",
  children: [
    {
      path: "/login",
      name: "Login",
      component: Login,
      meta: {
        middleware: [beforeAuth],
      },
    },
    {
      path: "/loginPage",
      name: "Login",
      component: Login,
      meta: {
        middleware: [beforeAuth],
      },
    },
  ],
};

const routes = [
  {
    path: "/",
    redirect: "/login",
    meta: {
      middleware: [beforeAuth],
    },
  },
  {
    path: "/login",
    redirect: "/loginPage",
    meta: {
      middleware: [beforeAuth],
    },
  },
  componentsMenu,
  authPages,
  {
    path: "/",
    component: Users,
    children: [
      {
        path: "users",
        name: "Users",
        components: { default: Users },
        meta: {
          middleware: [auth],
        },
      },
    ],
    path: "/users",
    component: Users,
    children: [
      {
        path: "users",
        name: "Users",
        components: { default: Users },
        meta: {
          middleware: [auth],
        },
      },
    ],
  },
];

export default routes;
