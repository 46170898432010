<template>
  <div class="md-layout">
    <div class="md-layout-item md-size-100">
      <template>
        <div class="md-layout">
          <div class="md-layout-item md-size-100">
            <md-card>
              <md-card-actions class="text-center">
                <div class="md-layout-item">
                  <md-field>
                    <md-input type="text" v-model="searchUser" placeholder="Search user..."></md-input>
                  </md-field>
                </div>
                <md-button type="submit" class="md-search" @click="SearchUserData()">Search</md-button>
                <md-button type="submit" class="md-success" @click="createUserModalHide(true)"
                  :disabled="hideCreateUser">Create User
                </md-button>
                <md-button type="submit" class="md-danger" @click="DoLogout()">Logout
                </md-button>
              </md-card-actions>
              <md-card-content>
                <md-table v-model="tableData" table-header-color="green">
                  <md-table-row slot="md-table-row" slot-scope="{ item }">
                    <md-table-cell md-label="User Id">{{ item.id }}</md-table-cell>
                    <md-table-cell md-label="Name">{{ item.name }}</md-table-cell>
                    <md-table-cell md-label="Company">{{ item.company }}</md-table-cell>
                    <!-- <md-table-cell md-label="Email">{{ item.id }}_user@gmail.com</md-table-cell> -->
                    <md-table-cell md-label="Created at">{{ printDate(item.created_date) }}</md-table-cell>
                    <md-table-cell md-label="Valid till">{{ printExpireDate(item.created_date, item.no_of_expire_month)
                    }}</md-table-cell>
                    <md-table-cell md-label="Months">{{ item.no_of_expire_month }}</md-table-cell>
                    <md-table-cell md-label="Created By">{{ item.created_by }}</md-table-cell>
                    <md-table-cell md-label="Status">{{ item.status }}</md-table-cell>
                    <md-table-cell md-label="Actions">
                      <md-button class="md-just-icon md-info" @click="showQrCodeModalHide(true, item)">
                        <md-icon>qr_code</md-icon>
                      </md-button>&nbsp;
                      <md-button class="md-just-icon md-info" @click="changePasswordModalHide(true, item)">
                        <md-icon>edit</md-icon>
                      </md-button>&nbsp;
                      <md-button class="md-just-icon md-danger" @click="DeleteUserRecords(item.id, item.firebaseId)">
                        <md-icon>delete</md-icon>
                      </md-button>
                    </md-table-cell>
                  </md-table-row>
                </md-table>
              </md-card-content>
            </md-card>
          </div>
        </div>
      </template>

      <!-- Create User Modal-->
      <template>
        <modal v-if="createUserModal" @close="createUserModalHide(false)">
          <template>
            <template slot="header">
              <h4 class="modal-title">Create User</h4>
            </template>
            <template slot="body">
              <div class="box-body">
                <hr>
                <div class="md-layout">
                  <div class="md-layout-item md-size-50 md-small-size-100">
                    <div class="md-layout">
                      <label class="md-layout-item md-size-25 md-form-label">
                        User Id
                      </label>
                      <div class="md-layout-item">
                        <md-field>
                          <md-input type="text" v-model="userId"></md-input>
                        </md-field>
                      </div>
                    </div>
                    <div class="md-layout">
                      <label class="md-layout-item md-size-25 md-form-label">
                        Name
                      </label>
                      <div class="md-layout-item">
                        <md-field>
                          <md-input type="text" v-model="name"></md-input>
                        </md-field>
                      </div>
                    </div>
                    <div class="md-layout">
                      <label class="md-layout-item md-size-25 md-form-label">
                        Company Name
                      </label>
                      <div class="md-layout-item">
                        <md-field>
                          <md-input type="text" v-model="companyName"></md-input>
                        </md-field>
                      </div>
                    </div>
                    <div class="md-layout">
                      <label class="md-layout-item md-size-25 md-form-label">
                        Password
                      </label>
                      <div class="md-layout-item">
                        <md-field>
                          <md-input type="password" v-model="password"></md-input>
                        </md-field>
                      </div>
                    </div>

                    <div class="md-layout" v-if="isAdminUser">
                      <label class="md-layout-item md-size-25 md-form-label">
                        App type
                      </label>
                      <div class="md-layout-item">
                        <md-radio v-model="appTypeMore" :value="true">More+</md-radio>
                        <md-radio v-model="appTypeMore" :value="false" v-if="!hideCreateUser">More lite</md-radio>
                      </div>
                    </div>

                    <div class="md-layout">
                      <label class="md-layout-item md-size-25 md-form-label">
                        Number of expiration month
                      </label>
                      <div class="md-layout-item">
                        <md-field>
                          <md-input type="text" v-model="noOfExpireMonth"></md-input>
                        </md-field>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </template>
            <template slot="footer">
              <md-button class="md-danger md-simple" @click="createUserModalHide(false)">Close</md-button>
              <md-button class="md-success" type="submit" :disabled="isDisabled" @click="CreateUser()">Create
              </md-button>
            </template>
          </template>
        </modal>
      </template>

      <!-- Edit User Modal-->
      <template>
        <modal v-if="editUserModal" @close="editUserModalHide(false)">
          <template>
            <template slot="header">
              <h4 class="modal-title">Update User Details</h4>
            </template>
            <template slot="body">
              <div class="box-body">
                <hr>
                <div class="md-layout">
                  <div class="md-layout-item md-size-50 md-small-size-100">
                    <div class="md-layout">
                      <label class="md-layout-item md-size-25 md-form-label">
                        User Id
                      </label>
                      <div class="md-layout-item">
                        <md-field>
                          <md-input type="text" v-model="userId"></md-input>
                        </md-field>
                      </div>
                    </div>
                    <div class="md-layout">
                      <label class="md-layout-item md-size-25 md-form-label">
                        Name
                      </label>
                      <div class="md-layout-item">
                        <md-field>
                          <md-input type="text" v-model="name"></md-input>
                        </md-field>
                      </div>
                    </div>
                    <div class="md-layout">
                      <label class="md-layout-item md-size-25 md-form-label">
                        Company Name
                      </label>
                      <div class="md-layout-item">
                        <md-field>
                          <md-input type="text" v-model="companyName"></md-input>
                        </md-field>
                      </div>
                    </div>
                    <div class="md-layout">
                      <label class="md-layout-item md-size-25 md-form-label">
                        Number of expiration month
                      </label>
                      <div class="md-layout-item">
                        <md-field>
                          <md-input type="text" v-model="noOfExpireMonth"></md-input>
                        </md-field>
                      </div>
                    </div>
                    <div class="md-layout">
                      <label class="md-layout-item md-size-25 md-form-label">
                        Password
                      </label>
                      <div class="md-layout-item">
                        <md-field>
                          <md-input type="password" v-model="password"></md-input>
                        </md-field>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </template>
            <template slot="footer">
              <md-button class="md-danger md-simple" @click="editUserModalHide(false)">Close</md-button>
              <md-button class="md-success" type="submit" :disabled="isDisabled" @click="UpdateUser()">Update
              </md-button>
            </template>
          </template>
        </modal>
      </template>

      <!-- Change User Modal-->
      <template>
        <modal v-if="changePasswordModal" @close="changePasswordModalHide(false)">
          <template>
            <template slot="header">
              <h4 class="modal-title">Reset Password</h4>
            </template>
            <template slot="body">
              <div class="box-body">
                <hr>
                <div class="md-layout">
                  <div class="md-layout-item md-size-50 md-small-size-100">
                    <div class="md-layout">
                      <label class="md-layout-item md-size-25 md-form-label">
                        New Password
                      </label>
                      <div class="md-layout-item">
                        <md-field>
                          <md-input type="password" v-model="password"></md-input>
                        </md-field>
                      </div>
                    </div>
                    <div class="md-layout">
                      <label class="md-layout-item md-size-25 md-form-label">
                        Confirm Password
                      </label>
                      <div class="md-layout-item">
                        <md-field>
                          <md-input type="password" v-model="confirm_password"></md-input>
                        </md-field>
                      </div>
                    </div>
                    <div class="md-layout" v-if="!hideCreateUser">
                      <label class="md-layout-item md-size-25 md-form-label">
                        Number of expiration month
                      </label>
                      <div class="md-layout-item">
                        <md-field>
                          <md-input type="text" v-model="noOfExpireMonth"></md-input>
                        </md-field>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </template>
            <template slot="footer">
              <md-button class="md-danger md-simple" @click="changePasswordModalHide(false)">Close</md-button>
              <md-button class="md-success" type="submit" :disabled="isDisabled" @click="ResetUserPassword()">Reset
              </md-button>
            </template>
          </template>
        </modal>
      </template>

      <!-- Show QR Code -->
      <template>
        <modal v-if="showQRCodeModel" @close="showQrCodeModalHide(false)">
          <template>
            <template slot="header">
              <h4 class="modal-title">QR Code</h4>
            </template>
            <template slot="body">
              <div class="box-body">
                <hr>
                <div class="md-layout">
                  <qrcode :background="background" :size="size" :cls="qrCls" :value="qrText"></qrcode>
                </div>
              </div>
            </template>
            <template slot="footer">
              <md-button class="md-danger md-simple" @click="showQrCodeModalHide(false)">Close</md-button>
            </template>
          </template>
        </modal>
      </template>
    </div>
  </div>
</template>

<script>
import ApplicationUserDataService from "../../services/ApplicationUserDataService";
import Swal from "sweetalert2";
import { Modal } from "@/components";
import moment from 'moment';
import Qrcode from 'v-qrcode/src/index';
export default {
  components: {
    Modal,
    Qrcode,
  },
  data() {
    return {
      qrCls: 'qrcode',
      qrText: "",
      size: 300,
      background: '#FFFFFF',
      tableData: [],
      firebaseAuthUsers: [],
      allFirebaseUsersData: [],
      userId: null,
      name: null,
      password: null,
      companyName: null,
      confirm_password: null,
      createUserModal: false,
      isDisabled: false,
      editUserModal: false,
      changePasswordModal: false,
      firebaseId: null,
      noOfExpireMonth: 6,
      hideCreateUser: false,
      searchUser: null,
      apiCount: 0,
      authApiCount: 0,
      appTypeMore: true,
      isAdminUser: true,
      showQRCodeModel: false,
    };
  },
  methods: {
    SearchUserData() {
      var userData = [];
      if (this.searchUser !== null) {
        this.allFirebaseUsersData.filter(user => {
          if ((user.id != null && user.id.includes(this.searchUser)) ||
            (user.name != null && user.name.includes(this.searchUser)) ||
            (user.company != null && user.company.includes(this.searchUser))) {
            userData.push(user);
          }
        });
        this.tableData = userData;
      } else {
        this.tableData = this.allFirebaseUsersData;
      }
    },
    GetApplicationUserList() {
      ApplicationUserDataService.GetApplicationUserList(localStorage.getItem('userId'))
        .then((response) => {
          this.apiCount = 0;
          if (localStorage.getItem('userId')) {
            if (localStorage.getItem('userId') === "admin2") {
              this.hideCreateUser = true;
            } else {
              this.hideCreateUser = false;
            }
          }
          if (response.data.data) {
            this.tableData = response.data.data;
            this.allFirebaseUsersData = response.data.data;
          }
        })
        .catch((e) => {
          this.isDisabled = false;
          if (e.response) {
            if (e.response.data.is_logout == 1) {
              //localStorage.setItem('token', "");
              //this.$router.push('login');
            } else {
              if (this.apiCount < 3) {
                setTimeout(() => {
                  this.GetApplicationUserList();
                }, 1000);
              } else {
                this.$toaster.error(e);
              }
              this.apiCount = this.apiCount + 1;
            }
            this.$toaster.error(e.response.data.respones_msg);
          } else {
            if (this.apiCount < 3) {
              setTimeout(() => {
                this.GetApplicationUserList();
              }, 1000);
            } else {
              this.$toaster.error(e);
            }
            this.apiCount = this.apiCount + 1;
          }
        });
    },
    GetAllAuthUsersList() {
      ApplicationUserDataService.GetAllAuthUsersList()
        .then((response) => {
          this.authApiCount = 0;
          if (response.data.data) {
            this.firebaseAuthUsers = response.data.data;
          }
        })
        .catch((e) => {
          if (e.response) {
            if (e.response.data.is_logout == 1) {
              //localStorage.setItem('token', "");
              //this.$router.push('login');
            } else {
              if (this.authApiCount < 3) {
                setTimeout(() => {
                  this.GetApplicationUserList();
                }, 1000);
              } else {
                //this.$toaster.error(e);
              }

              this.authApiCount = this.authApiCount + 1;
            }
            this.$toaster.error(e.response.data.respones_msg);
          } else {
            if (this.authApiCount < 3) {
              setTimeout(() => {
                this.GetApplicationUserList();
              }, 1000);
            } else {
              //this.$toaster.error(e);
            }

            this.authApiCount = this.authApiCount + 1;
          }
        });
    },
    DeleteApplicationUser(id) {
      Swal.fire({
        title: "Are you sure?",
        text: `You won't be able to revert this!`,
        type: "warning",
        showCancelButton: true,
        confirmButtonClass: "md-button md-success",
        cancelButtonClass: "md-button md-danger",
        confirmButtonText: "Yes, delete it!",
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          var email = id + "_user@gmail.com";
          if (this.firebaseAuthUsers.length > 0) {
            var user = this.firebaseAuthUsers.find(data => data.email == email)
            if (user) {
              ApplicationUserDataService.DeleteUser(user.uid)
                .then((response) => {
                  this.$toaster.success(response.data.respones_msg);
                  this.GetApplicationUserList();
                  this.GetAllAuthUsersList();
                })
                .catch((e) => {
                  if (e.response) {
                    if (e.response.data.is_logout == 1) {
                      localStorage.setItem('token', "");
                      this.$router.push('login');
                    }
                    this.$toaster.error(e.response.data.respones_msg);
                  }
                });
            }
          }
        }
      });
    },
    ResetApplicationUserPassword(id) {
      Swal.fire({
        title: "Are you sure?",
        text: `You won't be able to revert this!`,
        type: "warning",
        showCancelButton: true,
        confirmButtonClass: "md-button md-success",
        cancelButtonClass: "md-button md-danger",
        confirmButtonText: "Yes, reset it!",
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          var email = id + "_user@gmail.com";
          if (this.firebaseAuthUsers.length > 0) {
            var user = this.firebaseAuthUsers.find(data => data.email == email)
            if (user) {
              ApplicationUserDataService.DeleteUser(user.uid)
                .then((response) => {
                  this.$toaster.success(response.data.respones_msg);
                  this.GetApplicationUserList();
                  this.GetAllAuthUsersList();
                })
                .catch((e) => {
                  if (e.response) {
                    if (e.response.data.is_logout == 1) {
                      localStorage.setItem('token', "");
                      this.$router.push('login');
                    }
                    this.$toaster.error(e.response.data.respones_msg);
                  }
                });
            }
          }
        }
      });
    },
    DoLogout() {
      this.apiCount = 0;
      this.authApiCount = 0;
      localStorage.setItem('token', "");
      this.$router.push('/login');
    },
    CreateUser() {
      var appType = (this.appTypeMore) ? "more" : "chatLite";
      if (localStorage.getItem('userId') === "admin3" || localStorage.getItem('userId') === "admin4" || localStorage.getItem('userId') === "admin5") {
        appType = "parrot";
      }

      var data = {
        "userId": this.userId.trim(),
        "name": this.name.trim(),
        "password": this.password.trim(),
        "companyName": this.companyName.trim(),
        "no_of_expire_month": this.noOfExpireMonth,
        "app_type": appType,
        "created_by": localStorage.getItem('userId')
      };

      ApplicationUserDataService.CreateUser(data)
        .then((response) => {
          this.$toaster.success(response.data.respones_msg);
          this.GetApplicationUserList();
          this.GetAllAuthUsersList();
          this.isDisabled = true;
          this.createUserModalHide(false);
          this.isDisabled = false;
        })
        .catch((e) => {
          if (e.response) {
            this.isDisabled = true;
            this.createUserModalHide(false);
            this.isDisabled = false;
            if (e.response.data.is_logout == 1) {
              localStorage.setItem('token', "");
              this.$router.push('login');
            }
            this.$toaster.error(e.response.data.respones_msg);
          }
        });
    },
    UpdateUser() {
      var data = {
        "userId": this.userId.trim(),
        "name": this.name.trim(),
        "password": this.password.trim(),
        "companyName": this.companyName.trim()
      };
      this.isDisabled = true;
      this.editUserModalHide(false);
      this.isDisabled = false;
    },
    ResetUserPassword() {
      var data = {
        "userId": this.userId.trim(),
        "email": this.userId.trim() + "_user@gmail.com",
        "password": this.password.trim(),
        "firebaseId": this.firebaseId.trim(),
        "confirmPassword": this.confirm_password.trim(),
        "no_of_expire_month": this.noOfExpireMonth
      };
      ApplicationUserDataService.ResetUserPassword(data)
        .then((response) => {
          this.$toaster.success(response.data.respones_msg);
          this.GetApplicationUserList();
          this.GetAllAuthUsersList();
          this.changePasswordModalHide(false);
          this.isDisabled = false;
          this.firebaseId = null;
        })
        .catch((e) => {
          if (e.response) {
            this.$toaster.error(e.response.data.respones_msg);
            this.changePasswordModalHide(false);
            this.isDisabled = false;
            this.firebaseId = null;
            if (e.response.data.is_logout == 1) {
              localStorage.setItem('token', "");
              this.$router.push('login');
            }
          }
        });
    },
    DeleteUserRecords(userId, firebaseId) {
      var data = {
        "userId": userId.trim(),
        "email": userId.trim() + "_user@gmail.com",
        "firebaseId": firebaseId.trim()
      };
      Swal.fire({
        title: "Are you sure?",
        text: `You won't be able to revert this!`,
        type: "warning",
        showCancelButton: true,
        confirmButtonClass: "md-button md-success",
        cancelButtonClass: "md-button md-danger",
        confirmButtonText: "Yes, delete it!",
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          ApplicationUserDataService.DeleteUserRecords(data)
            .then((response) => {
              this.$toaster.success(response.data.respones_msg);
              this.isDisabled = false;
              this.firebaseId = null;
              this.GetApplicationUserList();
              this.GetAllAuthUsersList();
            })
            .catch((e) => {
              if (e.response) {
                this.$toaster.error(e.response.data.respones_msg);
                this.isDisabled = false;
                this.firebaseId = null;
                if (e.response.data.is_logout == 1) {
                  localStorage.setItem('token', "");
                  this.$router.push('login');
                } else {
                  this.GetApplicationUserList();
                  this.GetAllAuthUsersList();
                }
              }
            });
        }
      });
    },
    createUserModalHide: function (value) {
      if (value == true) {
        this.userId = null;
        this.password = null;
        this.confirm_password = null;
        this.name = null;
        this.companyName = null;
        this.appTypeMore = true;
      }
      this.createUserModal = value;
    },
    editUserModalHide: function (value, data) {
      if (value == true) {
        if (data) {
          this.userId = data.id.trim();
          this.name = data.name.trim();
          this.companyName = data.company.trim();
        } else {
          this.userId = null;
          this.name = null;
          this.companyName = null;
        }

        this.password = null;
        this.confirm_password = null;
      }
      this.editUserModal = value;
    },
    changePasswordModalHide: function (value, data) {
      if (data) {
        this.firebaseId = data.firebaseId.trim();
        this.userId = data.id.trim();
        if (data.no_of_expire_month)
          this.noOfExpireMonth = data.no_of_expire_month;
        else
          this.noOfExpireMonth = 6;
      }

      if (localStorage.getItem('userId')) {
        if (localStorage.getItem('userId') === "admin2") {
          this.hideCreateUser = true;
        } else {
          this.hideCreateUser = false;
        }
      }
      if (value == true) {

      } else {
        this.noOfExpireMonth = 6;
      }

      this.changePasswordModal = value;
    },
    showQrCodeModalHide: function (value, data) {
      if (data) {
        this.firebaseId = data.firebaseId.trim();
        this.userId = data.id.trim();
        this.password = data.password.trim();

        const req = {
          "user_id": this.userId,
          "password": this.password
        };

        this.qrText = JSON.stringify(req)
      } else {
        this.qrText = "";
      }
      this.showQRCodeModel = value;
    },
    setIsAdminUser: function () {
      if (localStorage.getItem('userId')) {
        if (localStorage.getItem('userId') === "admin") {
          this.isAdminUser = true;
        } else {
          this.isAdminUser = false;
        }
      }
    },
    printDate: function (dateStamp) {
      const date = moment(dateStamp).toDate();
      return moment(date).format("DD/MM/YYYY");
    },
    printExpireDate: function (dateStamp, noOfMonth) {
      var month = 6;
      if (noOfMonth && noOfMonth !== null)
        month = noOfMonth;

      const date = moment(dateStamp).toDate();
      const futureDate = moment(date).add(month, 'M');
      return futureDate.format("DD/MM/YYYY");
    },
  },
  beforeMount() {
    this.authApiCount = 0;
    this.apiCount = 0;
    this.setIsAdminUser();
    setTimeout(() => {
      this.GetApplicationUserList();
      this.GetAllAuthUsersList();
    }, 2000);
  },
};
</script>
<style>
/*.md-card-header {
  border-bottom: 1px solid black;
  padding: 5px !important; 
}*/
.tab-content {
  display: unset !important;
}

input[type="text"]:disabled {
  background-color: #dddddd;
}

.modal {
  min-height: 400px;
  height: 400px;
  overflow: scroll;
}

.iframe-hieght {
  min-height: 450px !important;
  height: 450px !important;
  overflow: scroll;
}

.modal-container {
  max-width: 900px !important;
}

.loader-img {
  display: block;
  margin-left: auto;
  margin-right: auto;
  height: unset !important;
  width: unset !important;
}

.md-table-head {
  background: #ebedee;
}

.autoconnect .md-checkbox .md-checkbox-label {
  position: unset !important;
}
</style>
