<template>
  <div class="user">
    <div class="photo">
      <img :src="avatar" alt="avatar" />
    </div>
    <div class="user-info">
      <a
        data-toggle="collapse"
        :aria-expanded="!isClosed"
        @click.stop="toggleMenu"
        @click.capture="clicked"
      >
        <span v-if="$route.meta.rtlActive">
          {{ rtlTitle }}
          <b class="caret"></b>
        </span>
        <span v-else>
          {{ title }}
          <b class="caret"></b>
        </span>
      </a>

      <collapse-transition>
        <div v-show="!isClosed">
          <ul class="nav">
            <slot>
              <li>
                <a href="#vue">
                  <span class="sidebar-mini">MP</span>
                  <span class="sidebar-normal">My Profile</span>
                </a>
              </li>
              <li>
                <a @click="DoLogout()">
                  <span class="sidebar-mini">L</span>
                  <span class="sidebar-normal">Logout</span>
                </a>
              </li>
            </slot>
          </ul>
        </div>
      </collapse-transition>
    </div>
  </div>
</template>
<script>
import { CollapseTransition } from "vue2-transitions";
import UserDataService from "../../../../services/UsersDataService";
export default {
  components: {
    CollapseTransition,
  },
  props: {
    rtlTitle: {
      type: String,
      default: "تانيا أندرو",
    },
    avatar: {
      type: String,
      default: "./img/faces/avatar.jpg",
    },
  },
  data() {
    return {
      isClosed: true,
      title: "Tania Andrew"
    };
  },
  methods: {
    clicked: function (e) {
      e.preventDefault();
    },
    toggleMenu: function () {
      this.isClosed = !this.isClosed;
    },
    GetAdminDetail() {
      var data = {};
      UserDataService.getUserDetail(data)
        .then((response) => {
          this.title = response.data.data.name;
        })
        .catch((e) => {
          if (e.response.data.is_logout == 1) {
            localStorage.setItem('token', "");
            this.$router.push('login');
          }
          this.$toaster.error(e.response.data.respones_msg);
        });
    },
    DoLogout() {
      localStorage.setItem('token', "");
      this.$router.push('login');
    },
  },
  beforeMount(){
    this.GetAdminDetail()
 },
};
</script>
<style>
.collapsed {
  transition: opacity 1s;
}
</style>
