<template>
  <footer class="footer">
    <div class="container">
      <nav>
        <ul>
          <!--<li>
            <a href=""> Blog </a>
          </li>
          <li>
              <a href=""> Licenses </a>
          </li>-->
        </ul>
      </nav>
      <div class="copyright text-center">
        &copy; {{ new Date().getFullYear() }}
        <a href=""
          >Chat Portal</a>
      </div>
    </div>
  </footer>
</template>
<script>
export default {};
</script>
<style></style>
