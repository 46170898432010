<template>
  <div class="md-layout text-center">
    <div class="
        md-layout-item
        md-size-33
        md-medium-size-50
        md-small-size-70
        md-xsmall-size-100
      ">
      <login-card header-color="green">
        <h4 slot="title" class="title">Log in</h4>
        <!--<md-button
          slot="buttons"
          href="#facebook"
          class="md-just-icon md-simple md-white"
        >
          <i class="fab fa-facebook-square"></i>
        </md-button>
        <md-button
          slot="buttons"
          href="#twitter"
          class="md-just-icon md-simple md-white"
        >
          <i class="fab fa-twitter"></i>
        </md-button>
        <md-button
          slot="buttons"
          href="#google"
          class="md-just-icon md-simple md-white"
        >
          <i class="fab fa-google-plus-g"></i>
        </md-button>
        <p slot="description" class="description">Or Be Classical</p>-->
        <md-field class="md-form-group" slot="inputs">
          <md-icon>email</md-icon>
          <label>Email...</label>
          <md-input v-model="email" type="email"></md-input>
        </md-field>
        <md-field class="md-form-group" slot="inputs">
          <md-icon>lock_outline</md-icon>
          <label>Password...</label>
          <md-input v-model="password" type="password"></md-input>
        </md-field>
        <md-button slot="footer" class="md-simple md-success md-lg" v-on:click="Login()">
          Lets Go
        </md-button>
      </login-card>
    </div>
  </div>
</template>
<script>
import { LoginCard } from "@/components";
import UserDataService from "../../../services/UsersDataService";
export default {
  components: {
    LoginCard,
  },
  data() {
    return {
      email: null,
      password: null,
    };
  },
  methods: {
    Login() {
      var data = {
        email: this.email,
        password: this.password,
      };
      //document.location = "/users";
      UserDataService.login(data)
        .then((response) => {
          localStorage.setItem('userId', this.email);
          this.$toaster.success(response.data.respones_msg);
          this.email = null;
          this.password = null;
          localStorage.setItem('token', response.data.data.token);
          //document.location = "/users";
          this.$router.push("/users");
        })
        .catch((e) => {
          this.$toaster.error(e.response.data.respones_msg);
        });
    },
  },
};
</script>

<style>

</style>
