import axios from "axios";

const headers = { "Content-Type": "application/json" };
if (localStorage.getItem('token')) {
  headers.Authorization = localStorage.getItem('token');
}
axios.defaults.maxContentLength = 52428800;
export default axios.create({
  baseURL: "https://portal.moredevelopers.chat:3000/",
  headers: headers
});