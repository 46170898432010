import http from "../http-common";

class UserDataService {
  get(id) {
    return http.get(`/tutorials/${id}`);
  }

  register(data) {
    return http.post("/users/register", data);
  }

  login(data) {
    return http.post("/login", data);
  }

  getUserDetail(data) {
    return http.get("/users/detail", data);
  }
}

export default new UserDataService();