import http from "../http-common";

class ApplicationDataService {
    GetApplicationUserList(userId) {
        return http.get("database-users?userId=" + userId);
    }
    GetAllAuthUsersList() {
        return http.get("firebase-auth-users");
    }
    DeleteUser(id) {
        return http.get("delete-user?package_name=" + id);
    }
    CreateUser(data) {
        return http.post("create-user", data);
    }
    DeleteUserRecords(data) {
        return http.post("delete-user-details", data);
    }
    DeleteApplicationUser(data) {
        return http.post("application-user/delete", data);
    }
    ResetUserPassword(data) {
        return http.post("reset-user-password", data);
    }
    GetApplicationUserDetail(id) {
        return http.get("application-user/detail?id=" + id);
    }
    UpdateUser(data) {
        return http.post("application-user/update", data);
    }
    GetDevicesList() {
        return http.get("application-user/devices/list");
    }
}

export default new ApplicationDataService();